@import 'variables';

// Import CoreUI for React components library
@import '@coreui/coreui/scss/coreui';
@import '@coreui/icons/css/all.css';

@import '~react-datepicker/src/stylesheets/datepicker';

@import 'utils';

@import 'layout';

@import 'app-toast';

@import "/node_modules/react-grid-layout/css/styles.css";
@import "/node_modules/react-resizable/css/styles.css";

.react-datepicker-wrapper {
  width: auto;
}

img {
  max-width: 100%;
}

.react-grid-item.react-grid-placeholder {
  background-color: rgba(50, 31, 219, 0.5);
}

.form-floating > label {
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100%;
  overflow: hidden;
}

.is-draggable .draggable-header {
  cursor: move;
}

.login-with-button {
  background-color: rgb(255, 255, 255);
  display: inline-flex;
  align-items: center;
  color: rgba(0, 0, 0, 0.54);
  box-shadow: rgba(0, 0, 0, 0.24) 0 2px 2px 0, rgba(0, 0, 0, 0.24) 0 0 1px 0;
  padding: 10px;
  margin-top: 10px;
  border-radius: 2px;
  border: 1px solid transparent;
  font-size: 14px;
  font-weight: 500;
  font-family: Roboto, sans-serif;
}