.spinner-overlay {
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 1100;

    &--transparent {
        background-color: rgba(#000015, 0.5);
    }
}