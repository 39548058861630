body {
  background-color: var(--cui-light, #ebedef);
}

.wrapper {
  width: 100%;
  @include ltr-rtl('padding-left', var(--cui-sidebar-occupy-start, 0));
  will-change: auto;
  @include transition(padding 0.15s);
}

.header-nav {
  .active {
    text-decoration: underline !important;
  }
}
