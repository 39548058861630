.min-vh-100-nav {
  min-height: calc(100vh - 65px);
}

.p-main {
  padding-top: 65px;
}

.z-index-100 {
  z-index: 100;
}

.z-index-1301 {
  z-index: 1301;
}

.text-center {
  text-align: center !important;
}

.text-right {
  text-align: right !important;
}

.text-sm-left {
  @media screen and (min-width: 576px) {
    text-align: left !important;
  }
}

.text-sm-center {
  @media screen and (min-width: 576px) {
    text-align: center !important;
  }
}

.text-sm-right {
  @media screen and (min-width: 576px) {
    text-align: right !important;
  }
}

.flex-1 {
  flex: 1;
}
